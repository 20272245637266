.edit-contribution-page {
  color: #ffffff;
}


.edit-contribution-rest {
  background-color: #f2f2f2;
  padding: 30px 10%;
  color: #000;
  margin-top: 50px;
  min-height: 100px;
  
  margin: 50px auto;
}

.edit-public-contrib-checkbox-wrapper {
  margin:auto;
  width: 50%;
}

#edit-ctb-last-step-btns .btn-danger {
  margin:15px;
}


.edit-contribution-admin-section-container {
  margin-top: 20px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.edit-contribution-admin-section-container h2 {
  color: #000;
}

@media screen and (min-width: 600px) {
  .edit-contribution-rest{ 
    width: 500px;
  }
}