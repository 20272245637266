
 .app-footer {
  background-color: black;
  min-height: 5vh;  
}

.footer-content {
  padding: 200px 30px 20px 20px;
  color: #FFFFFF;
}

.footer-div-divider {
  border: 1px solid #ffffff;
  max-width: 150px;
}


@media screen  and  (min-width: 600px){
  .footer-content  {
  padding-bottom: 30px;
  grid-template-columns: 1fr 1fr;
  display: grid;
  }

}
.footer-list-wrapper p {
  font-size: large;
}
.footer-list-wrapper hr {
  color: #FFFFFF;
}

.footer-list-wrapper.list1 {
  justify-self: flex-end;
}
.footer-list-wrapper.list2 {
  justify-self: flex-start;
}

ul.footer {
  padding: 0px 0px 5px 0px;
  list-style: none;
  display: grid;
  margin-right: 50px;

}

ul.footer li {
  display: inline;
  font-size: 18px;
  color: #FFFFFF;
  margin-bottom: 10px;
}


ul.footer li a {
  color: #ffffff;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.footer li a:hover {
  color: #01447e;
  text-decoration: underline;
}