/* body {
  background-color: #2b6777;
} */

/* Default color: #556271 */
html, body{
    height: 100%;
    margin: 0;
    padding: 0;
}
#root {
  background-color: #000;
}
:root {
  --primary-color: #2b6777;
  --secondary-color: #52ab98;
  --font-color: #424242;
  --bg-color-light: #ffffff;
  --bg-color-dark: #2b6777;
  --heading-color: #292922;
}
.App {
  text-align: center;
}

.fade-appear,
.fade-enter {
  opacity: 0;
}

.fade-appear-active,
.fade-enter-active {
  transition: opacity .3s linear;
  opacity: 1;
}

.fade-exit {
  transition: opacity .2s linear;
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header-react {
  background-color: #282c34;
  min-height: 10vh;
  max-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-content {
  background-color: #000;
  min-height: 90vh;
}

.error-page-content {
  background-color: #000;
  height: 100vh;
  color: #ffffff;
  padding: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


  .flex {
    display: flex;
  }

  .flex-center-align {
    display: flex;
    align-items: center;
  }

  .flex-center {
    display: flex;
    justify-content: center;
  }

/*::::::::::::: Genereal :::::::::::::::*/
.btn-default {
  background-color: #000; 
}
.btn-default-with-border {
  background-color: #000; 
  border: 1px solid #ffffff !important;
}

.btn-default-big {
  min-width: 150px;
}

.textItalic {
  font-style: italic;
}
.btn {
  border-radius: 3px;
  margin: 15px;
  padding: 20px 20px;
  color: #ffffff;
  font-weight: normal;
  max-width: 70vw;
  border:none;
  cursor: pointer;
  font-size: medium;
}

.btn-contribute {
  font-weight: 800;
}

.btn-contribute-big {
  font-weight: 800;
  font-size: medium !important;
}
.btn-danger {
  border-radius: 3px;
  margin:5px;
  padding: 20px 20px;
  color: #ffffff;
  background-color: rgb(170, 7, 7);
  font-weight: normal;
  max-width: 70vw;
  border:none;
  cursor: pointer;
  /* text-transform: capitalize; */
}

.btn-secondary {
  padding: 15px 15px;  
}

.btn-icon {
  border-radius: 3px;
  margin:5px;
  padding: 10px 15px;
  color: #ffffff;
  font-weight: normal;
  max-width: 50px;
  max-height: 36px;
  align-self: end;
  border:none;
  cursor: pointer;

}

@media screen  and  (min-width: 600px){
  .btn {
    max-width: unset;
  }
}

.btn-tertiery {
  color: #ffffff;
  text-decoration: underline;
  border: none;
  background-color: inherit;
}

.btn-caution {
  color: #ffffff;
  background-color: #c40000;
  text-decoration: none;
  border: none;
}

.loader-btn {
  position: relative;
  padding: 20px 35px;
  border-radius: 2px;
  margin:5px;
  border:none;
  cursor: pointer;
  
}
.loader-btn__text {
  font-weight: bold;
  color: #ffffff;
  transition: all 0.2s;
}

.loader-btn--loading .loader-btn__text {
  visibility: hidden;
  opacity: 0;
}
  
.loader-btn--loading::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.loader--loading::after {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: var(--primary-color);
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
  
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.btn-invert {
  background: #ffffff;
  border-radius: 4px;
  margin:5px;
  padding: 5px;
  border: none;
  /* color: #52ab98; */
  color: #2b6777;
  font-weight: bold;
}

.btn-stnd-al {
  min-width: 6rem;
  font-size: small;
}


button:disabled,
button[disabled]{
  background-color: #cccccc;
  opacity: 0.6;
  cursor: not-allowed;
}

.place-holder {
  margin:auto;
  color: grey;
  font-style: italic;
}

.btn-link-stnd-al {
  min-width: 6rem;
  display: inline-block;
}

a {
  font-weight: normal;
}

h1 {
  /* color:#ffff; */
  margin-bottom: 30px;
}

.m-1 {
  margin:10px
}
.m-2 {
  margin: 20px;
}

.m-l-1 {
  margin-left: 10px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-b-30 {
  margin-bottom: 30px;
}
.link-text-color {
  color: #ffffff;
}
.link-text {
  text-decoration: none;
  text-transform: none;
  font-size: small;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  margin-right: 10px;
}


.description-container {
  padding: 15px 20%;
  text-align: justify;
}

.p-2 {
  padding: 20px;
}

.p-1 {
  padding: 10px
}


@media (min-width: 600px) {
  .p-2 {
    padding: 20px 50px;
  }
}

.spinner--loading::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #a75353;
  border-radius: 50%;
  animation: loading-spinner 1s ease infinite;
}

@keyframes loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}




.contain-img video {
  max-width: 40vw;
  max-height: 40vh;
  margin: 0 5px;
}

input[type="number"]{ padding: 10px 10px; line-height: 18px; border-radius: 10px; border: 1px solid grey; }
input[type="tel"]{ padding: 10px 10px; line-height: 18px; border-radius: 10px; border: 1px solid grey; }
input[type="text"]{ padding: 10px 10px; line-height: 18px; border-radius: 10px; border: 1px solid grey; }
input[type="password"]{ padding: 10px 10px; line-height: 18px; border-radius: 10px; border: 1px solid grey; }
input[type="date"]{ padding: 10px 10px; line-height: 18px; border-radius: 10px; border: 1px solid grey; }
input[type="date"]:not(.has-value):before{
  color: lightgray;
  content: attr(placeholder);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
input[type=tel] {
    -moz-appearance:textfield; /* Firefox */
}

select{padding: 10px 10px; line-height: 18px; border-radius: 10px; border: 1px solid grey;}

input[type="checkbox"] {
  background-color: #52ab98;
  color: #ffffff;
}
/* input[type="file"]{ height: 50px; } */
textarea {
  padding: 10px 10px; 
  line-height: 18px; 
  border-radius: 10px; 
  border-radius: 10px; border: 1px solid grey;
}


.customCheckbox-rot {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid grey;
  background-color: #ffffff;
  padding: 2px;
}
.customCheckbox-rot-round {
  width: 25px;
  height: 25px;
  border-radius: 4px;
  border: 1px solid grey;
  background-color: #ffffff;
  border-radius: 20px;
  position: absolute; 
  top: 15px;
  right: 15px; 
  padding: 4px 2px 5px 2px;
}

.customCheckbox-container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}
.checkbox-with-icon {
  display: flex;
  align-items: center;
}



.customCheckbox-label {
  margin-left: 5px;
  line-height: 2;
  font-size: smaller;
}

.text-left {
  text-align: left;
}

.text-size-larger {
  font-size: larger;
}
.text-size-medium {
  font-size: medium;
}

@media (min-width: 600px) {
  .customCheckbox-rot-round {
    right: 20px; 
  }
}

/* ::::::::::::::::::::::::::::::::::::::: */


/*Custom drop down classes */

.wrapper {
  position: relative;
  /* width: 222px; */
  font-size: 1.6rem;
  user-select: none;
}

.header-test {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 3px;
  background-color: white;
  line-height: 38px;
  cursor: default;
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
  /* width: 300px; */
  min-height: 50px;
}

.header-test-c {
  border: 1px solid grey;
}

.header-title-test {
  margin-right: 15px;
  font-weight: 300;

}

.dd-list-test {
  position: absolute;
  z-index: 10;
  /* min-width: 50%; */
  width: 100%;
  max-height: 215px;
  border: 1px solid rgb(223, 223, 223);
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
  background-color: white;
  font-weight: 700;
  text-align: left;
  -webkit-overflow-scrolling: touch;
}

.dd-scroll-list-test {
  overflow-y: scroll;
  /* max-height: 215px; */
  /* padding: 15px; */

  max-height: calc(215px - 40px);
  padding: 10px 0;
  display: grid;
}

.dd-list-item-test {
  display: inline-block;
  overflow: hidden;
  /* width: 100%; */
  padding: 8px 10px;
  font-size: small;
  /* line-height: 1.6rem; */
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
  cursor: pointer;
}

/* ::::::::::::::::::::::::::::::::::::::: */


/*Custom toggleslider classes */
.toggle-slider-input {
  visibility: hidden;
}
.round-slider-container {
  width: 63px;
  height: 26px;
  background: #fff;
  border: 0.5px solid grey;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative; 
}
.round-slider {
  width: 25px;
  height: 22px;
  border-radius: 50%;
  background: #000;
  top: 20x;
  left: 2px;
  position: absolute;
  transition: 0.5s ease-in-out;
}



.toggle-checked{
  transform: translateX(32px);
}

.toggle-with-colors-off {

  background: rgb(85, 85, 85);
}
.toggle-with-colors-on {
  background: rgb(35, 169, 68);
}
#toggle-btn {
  display: flex;
  align-items: center;
}


