.turn-off-swiping-gestures {
  overscroll-behavior-x: none;
}

.gallery-page {
  background-color: #000;
  height: 100vh;
}

.fullscreen-full-test {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #000;
}
.projector-full-screen {
  height: 100vh;
  width: 100vw;
  padding-top: 20px;
  overflow: hidden;
  background-color: #000;
}
.fullscreen-not-full-test {
  height: 90vh;
  width: 100vw;
  overflow: hidden;
  background-color: #000;
}
.gallery {
  background-color: #000;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.gallery-window {
  background-color: #000;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.first-frame {
  left: -100vw;
  align-content: flex-end;
  animation: walk-left-first 200s linear normal;
  animation: walk-left-first 200s linear normal;
}

.trailing-frame {
  right: calc(-100vw - 0.6rem);
  align-content: flex-start;
  animation: walk-left 200s linear normal;
  animation: walk-left 200s linear normal;
}

/* .walk-left{
  animation: walk-left 50s linear normal;
} */

.gallery-frame {
  /* padding: 1rem; */
  height: 90vh;
  width: 100vw;

  position: absolute;

  white-space: nowrap;
  background-color: #000;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
  justify-content: flex-start;
}

.gallery-img-wrapper {
  display: block;
  max-width: 20vh;
  max-height: inherit;
}

.gallery-img {
  width: 20vh;
  max-height: 100%;
}

/* GALLERY IMAGE DETAIL VIEW */

.gallery-img-detail-view {
  color: rgb(231, 231, 231);
  background-color: #171717;
  border: 1px solid rgb(63, 63, 63);
  box-shadow: 2px 3px 90px 300px rgba(0, 0, 0, 0.75);
  transform: translate(-50%, -50%) scale(0.3);
  transform-origin: center;
  opacity: 0;
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  display: flex; /* Aligns children (img and details) in a row */
  justify-content: center; /* Centers the children horizontally */
  align-items: center; /* Centers the children vertically */
  gap: 1rem;
  padding: 1rem; /* Adds some space around the content */
  padding-top: 0;
  padding-right: 0;
  height: 60vh; /* Takes full viewport height */
  max-width: 800px;
  width: 100%;
}

.gallery-img-img {
  box-sizing: border-box;
  flex: 1; /* Takes up 1/3 of the flex container */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 2rem;
}

.gallery-img-img img {
  max-height: 100%; /* Sets a fixed height for the image */
  width: auto; /* Maintains the aspect ratio of the image */
  object-fit: cover; /* Ensures the image covers the area without distortion */

  max-width: 100%;
}

.gallery-img-details-form-container {
  width: 50%;
  max-width: 30rem;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.gallery-img-details {
  /* flex: 2;  */
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the text vertically */
  flex: 1;
}
.gallery-img-text {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.gallery-img-description {
  min-height: 5rem;
  flex: 1;
  max-height: 15rem;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1rem;
  border: 1px solid rgb(66, 66, 66);
}

.gallery-img-subtitle {
  padding: 1rem 0;
  display: flex;
  align-items: start;
  flex-direction: row;
  justify-content: space-between;
}

.gallery-img-subtitle > * {
}
.gallery-img-photographer {
  font-weight: bold;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.gallery-img-socials {
  display: flex;
  gap: 0.5rem;
}

.gallery-img-detail-view {
}

.enter,
.enter-active,
.exit,
.exit-active {
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.gallery-img-detail-view.enter {
  transform: translate(-50%, -50%) scale(0.3);
  opacity: 0;
}

.gallery-img-detail-view.enter-active {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.gallery-img-detail-view.exit {
  transform: translate(-50%, -50%) scale(1); /* Start from full size */
}

.gallery-img-detail-view.exit-active {
  transform: translate(-50%, -50%) scale(0.5); /* Scale down to smaller size */
}

.gallery-img-form-container,
.gallery-img-details-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 0rem;
  flex: 1;
  padding-right: 1rem;
}

.gallery-img-information {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0.5rem 0;
}
.gallery-img-information > * {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.gallery-img-top-bar {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  height: 2rem;
}
.close-button-container {
  display: flex;
  justify-content: center;
  width: 3rem;
  height: 100%;
  align-items: center;
  background-color: rgb(184, 34, 34);
  cursor: pointer;
}

.gallery-img-btn-container {
  display: flex;
  margin-right: 1rem;
  gap: 1rem;
}
.gallery-img-btn-container > * {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(28, 69, 134);
  background-color: rgb(28, 69, 134);
  height: 3rem;
  cursor: pointer;
  font-weight: bold;
}

.gallery-img-show-form-btn {
  background-color: rgb(28, 69, 134);

  margin-right: 1rem;
  display: flex;
  justify-content: center;
  position: relative;
}

.gallery-img-show-form-btn > * {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.gallery-img-show-form-btn-text {
}

.gallery-img-show-form-btn-icon {
  position: relative;
  right: 1rem;
  top: 1px;
}

/* GALLERY IMAGE FORM */

.gallery-img-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.gallery-img-form input {
  color: rgb(223, 223, 223);

  background-color: #171717;
}

.gallery-img-confirmation {
  display: flex;
  align-items: center;
  height: 100%;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-2-15 13:54:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */

/* .scale-in-center {
  -webkit-animation: scale-in-center 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-center 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
} */

/* ----------------------------------------------
 * Generated by Animista on 2022-2-15 13:57:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slidein {
  from {
    margin-left: -400px;
    width: 150%;
    max-height: 150%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes walk-left-first {
  from {
    transform: translateX(120vw);
    opacity: 1;
  }

  to {
    transform: translateX(-480vw);
    opacity: 1;
  }
}

@keyframes walk-left {
  from {
    transform: translateX(0vw);
    opacity: 1;
  }

  to {
    transform: translateX(-600vw);
    opacity: 1;
  }
}
