.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 800px;
}

.carousel-slide {
  display: inline-block;
  height: 500px;
  width: 100%;
  border-radius: 40px;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 2000ms;

}

.slideshowDots {
  text-align: center;
}
.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px 7px 0px;
  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: rgb(124, 105, 158);
}



@media screen  and  (min-width: 1501px) {
  .slideshow {
    max-width: 1000px;
  }
}