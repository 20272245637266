
.create-contribution-page {
  background-color: #000;
}
.create-contribution-page h1 {
  margin: 0px;
  padding: 20px 10px;
  color: #ffffff;
}

.create-contribution-lower {
  background-color: #f2f2f2;
  padding: 30px 10%;
}

.upload-button {
  margin: auto;
  height: 1cm;
  width: 4cm;
  cursor: pointer;
}


.uploaded-video-overlay{ 
  position: absolute;
  top: 55%;
  left: 20%;
  color: rgb(0, 0, 0);
  size: 20px;
  font-size: 22px;
  font-weight: 800;
  /* text-shadow: 2px 0 0 rgb(0, 0, 0); */
}

.upload-info-text {
  margin:auto;
  color: rgb(37, 37, 37);
  text-align: center;
}

.upload-info-box {
  text-align: center;
  border-radius: 10px;
  margin: 30px;
  width: 5cm;
  min-height: 1cm;
  background: rgb(222, 214, 214);
  height: 80%;
  display: inline-block;
  border: rgb(0, 0, 0);
  border-radius: 4px;

}

.image-preview-footer {
  width: 100%;
}

.image-preview-box {
  min-height: 3cm;
  overflow: wrap;
  border-radius: 10px;
  margin: auto;
  position:relative;
  padding: 10px;
}

.preview-media-video {
  width: 3cm;
}

.preview-media {
  position: relative;
  display: inline-block;
  max-width: 200px;
  max-height: 150px;
  margin: 3mm;
  cursor:default;
  filter: drop-shadow(0 4% 3% rgba(0, 0, 0, 0.536));
  vertical-align: bottom;
}

.mediaHasInformation {
  border: 5px solid #ff7e00;
}

.information-banner-wrapper {
  background-color: #8AB9F1;
  padding: 10px 15px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  margin: 10px 10px;
  /* max-width: 500px; */
}

.information-banner-content {
  display: flex;
  align-items: center;
}

.information-banner-content p {
  font-size: 14px;
  margin-left: 5px;
  /* color: #ffffff; */
}

.img-preview-input-btn {
  visibility: hidden;
}

.img-preview-input-btn::-webkit-file-upload-button {
  visibility: hidden;
}

.modal-video{
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;

}

.react-crop-img{
  max-width: 100%;
  max-height: 100%;
  /* height: 90%; */
  margin: auto; 
  overflow: hidden;
}

.react-crop-img > div{
  height: 100%;
  width: 100%; 
}

.crop-hint{
  margin-bottom: 15px;
  color: grey;
  font-weight: 800;
}

.crop-control {
  width: 100%; 
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 20; /* Sit on top */
  padding-top: 3%; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  max-height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  
}

.video-modal {
  background-color: #bbbcc7;
  /* padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 2%; */
  padding: 3%;
  margin: auto;
  margin: auto;
  border-radius: 20px;
  overflow-y: hidden;
  display: inline-table;
  top: 3%;
}

.image-modal{
  width: 90%;
  height: 90%;
}

/* Modal Content */
.modal-content {
  margin: auto;
  overflow:hidden;
  position: relative;
}

/* The Close Button */
.close-modal {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
  right: 10px;
  top: 5px;
  background: #d21e1e00;
  border: none;
  z-index: 7;
  position: absolute;
  display: block;
}

.close-modal:hover,
.close-modal:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.contribution-container {
    padding: 30px 10%
}
.read-collection-terms-container {
  display: flex;
}
.read-collection-terms-text {
  cursor: pointer;
  margin-left: 5px;
  font-size: smaller;
}

.related-files-sub {
  text-align: initial;
  margin-top: 10px; 
}
.related-files-sub > p {
  font-size: 14px;
}

.relatedfiles-input {
  background: #dbdada;
  width: 60vw;
  height:30px;
  padding: 5px 5px;
  margin-top: 10px;
  border-radius: 2px;
  border: 1px solid #bfbcbc;
}

.file-name {
  font-size: medium;
}

.media-preview-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}


.license-container {
  margin-top: 30px;
}


@keyframes fadein {
  from {
    opacity: 0;
    scale: 0.85;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

.pop-in {
  /* opacity: 1; */
  z-index: 20;
  /* animation: fadein 0.5s ease-in-out; */
  animation: fadein 0.2s ease-in-out;
  position: fixed;
  display: block;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  scale: 1;
}

.image-cropper {
  background-color: #d6d6d6;
  box-shadow: inset rgb(255 255 255) 0px 0px 13px 3px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  margin: none;
 
  grid-template-rows: 90px 20px auto;
  display: grid;
  position: absolute;

}


.public-contrib-checkbox-wrapper {
  display: flex;
  /* justify-content: center; */
}
.preview-area {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    border-radius: 4px;
    min-height: 20vh;
    margin-bottom: 30px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  
  .preview-area>* {
    /* flex: 1 1 160px; */
    margin: 10px;
  }
  
  .preview-area img {
    max-width: 20vw;
    max-height: 20vh;
    /* margin: 0 5px; */
}

.edit-information {
  font-size: 20px;
  text-align: left;
  padding: 30px 0 0 10%;
}

.img-row {
  border: 1px solid grey;
  box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
  border-radius: 4px;
  cursor: pointer;
}


.img-row.focused {
  border: 5px solid var(--secondary-color);
}

.other-socails-input-container {
  font-size: medium;
  margin-top: 15px;
}

.other-socials-actions {
  margin-top:15px;
  display: grid;
}
.other-socials-actions-buttons {
  display: grid;
}

#map  {
height: 50%;
}

.pac-container { 
  z-index: 2147483650 !important; 
  pointer-events: auto;
}
    


form {
  background-color: rgb(241,241,241);
  padding: 20px;
}

.fields-container {
  text-align: left;
}
    
.fields-container form fieldset {
  border: none;
  
}

.field-pair {
  display: grid;
  text-align: left;
  font-size: medium;
  margin-bottom: 10px;
}

.field-pair label {
  margin-bottom: 10px;
}
.field-pair textarea {
  height: 100px;
  resize: vertical;
}

.field-pair p {
  font-size: small;
  font-style: italic;
  margin-top: 5px;
  margin-bottom: 10px;
}
    
.tooltip-button {
  border-radius: 100%;
  height: 20px;
  width: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: unset;
  margin-left: 5px;

}

.tooltip-content {
  background-color: white;
  border-radius: 4px;
  font-size: 12px;
  color: black;
  padding: 10px;
  margin:10px;
  border: 1px solid rgb(192, 192, 192);
  max-width: 150px;
  overflow-wrap: break-word;
}

@media  screen and (min-width: 900px) {
  .relatedfiles-input {
    width: 20vw;
    padding-top:10px;
  }

  #contrib-form {
    width: 500px;
  }
}

.h-middle{
  left: 0px;
  right: 0px;
}
    
@media (min-width: 600px) {
  .fields-container {
    display: grid;
    justify-content: center;
  }
  .edit-information{
    padding: 30px 30%;
  }
  .other-socials-actions-buttons {
    display: flex;
  }
  .other-socials-actions {
    display: flex;
  }
}
    
/* ------- Testing scroll abr css */
#style-4::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  
#style-4::-webkit-scrollbar{
  width: 5px;
  background-color: #F5F5F5;
}
  
#style-4::-webkit-scrollbar-thumb{
  /* background-color: #000000; */
  background-color: #2b6777;
  /* border: 3px solid #555555; */
}
  /* ------ end test  */
  
.tags{
  display: flex;
  flex-wrap: wrap;
  /* width: 500px; */
  height: 50px;
  /* border: 1px solid black; */
  padding: 5px 1px;
  align-items: baseline;
  align-content: center;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.tags::-webkit-scrollbar {
  display: none;
}
  
.tag {
  background-color: black;
  color: #ffff;
  border-radius: 6px;
  border: 1px solid #ffff;
  font-size: small;
  font-weight: bold;
  padding: 10px;
  display: flex;
}
.options {
  display: flex;
  flex-wrap: wrap;
}
  
.option {
  background-color: rgb(218, 218, 218);
  color: rgb(54, 52, 52);
  border-radius: 4px;
  border: 1px solid rgb(218, 218, 218);
  font-size: small;
  font-weight: bold;
  padding: 2px 5px;
  height: 30px;
  display: flex;
  margin: 2px 2px;
  align-items: center;
}

.option-text {
  margin-right: 5px;
}
  
.map-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.map-container__map {
  height: 40vh;
  width: 100%;
}

/* Media preview */

.mediaPreview-container {
  /* background-color: #ffffff;
  border: 1px solid rgb(224, 224, 224);
  padding: 5px; */
  margin: 5px;
  /* width: 200px;
  height: 150px; */
  position: relative;
}

.crop-btn {
  background-color: white;
  border: 1px solid #c3c1c1;
  cursor: pointer;
  border-radius: 5px;
  padding-top:3px;
  margin-right: 5px;
}

.media-with-error {
  border: 1px solid red;
}
.selected-preview-media {
  outline: 2px solid #ffffff;
}


    