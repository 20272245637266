
.platform-developer-menu-trigger {
  border:none;
  background-color: transparent;
  color: #ffffff;
}
.platform-developer-menu-trigger:focus-visible {
  outline: none;
}


.platform-developer-menu-content {
  min-width: 150px;
  background-color: #fff;
  border-radius: 6px;
  padding: 5px;
}

.edit-plaform-page > h1  {
  color: #ffffff;
}

.menu-label {
  padding-left: 25px;
  font-size: 12px;
  line-height: 25px;
  color: gray;
}

.menu-separator {
  height: 1px;
  background-color: gray;
  margin: 5px;
}