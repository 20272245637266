
.home {
  background-color: #000;
  color: #ffffff;
}

.home h1 {
  color: #ffffff;
  margin-top: 0px;
  margin-bottom: 50px;
}

.home-signup {
  margin-top: 100px;
}
.home-signup a {
  color: #ffffff;
}


.organzation {
  cursor: pointer;
  color: #ffffff;
  margin: 20px;
  padding: 30px;
  box-shadow: 0.3px 0.5px 0.7px rgb(0 0 0 / 10%), 0.8px 1.6px 2px -0.8px rgb(0 0 0 / 10%), 2.1px 4.1px 5.2px -1.7px rgb(0 0 0 / 10%), 5px 10px 12.6px -2.5px rgb(0 0 0 / 10%); 
  transition: all .2s ease-in-out;
}

.organzation:hover {
  transform: scale(1.05);
}

.organzation h3 {
  font-weight: normal;
}

.organizations {
  display: block;
}


@media screen  and  (min-width: 600px){
  .organizations {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}