.virtual-keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  background-color: rgb(32, 32, 32);
  width: 100%;
  top: 100%;
  left: 0rem;
  padding: 1rem 0;
}

.keyboard-row {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  width: 100%;
}

.keyboard-button {
  font-size: 16px;
  min-height: 3rem;
  min-width: 4rem;
  cursor: pointer;
  border: 1px solid #242424;
  border-radius: 5px;
  background-color: #313131;
  transition: background-color 0.3s;
  color: white;
}

.keyboard-button.enter {
  background-color: rgb(28, 69, 134);
  width: 10rem;
  font-size: 1.2rem;
}

.keyboard-button.shift {
  background-color: rgb(64, 65, 66);
  font-size: 1.2rem;
  width: 10rem;
}
.keyboard-button.backspace {
  width: 8rem;
  font-size: 1.2rem;
}

.keyboard-button:active {
  background-color: #636363;
}
