.edit-collection-page > h1 {
  color: #ffffff;
}
.edit-collection-page > h2 {
  color: #ffffff;
}

.edit-collection-container {
  padding: 30px 10%;
  border-radius: 3px;
  background-color: #f2f2f2 ;
  box-shadow: 0.3px 0.5px 0.7px rgb(0 0 0 / 10%), 0.8px 1.6px 2px -0.8px rgb(0 0 0 / 10%), 2.1px 4.1px 5.2px -1.7px rgb(0 0 0 / 10%), 5px 10px 12.6px -2.5px rgb(0 0 0 / 10%);

}

.show-questions-container {
  padding: 30px;
}

.collection-questions-container {
  background-color: #ffffff;
  padding: 10px;
  margin:auto;
}
.collection-questions-wrapper {
  display: grid;
  grid-template-columns: 3fr 0fr;
  margin-bottom: 10px;
  border-bottom:1px solid #f2f2f2;
}
.collection-questions-wrapper span {
  text-align: left;
}

.collection-group{
  text-align: left;
  margin: 0px 20px 40px 0px;
}

.collection-group select{
  width: 250px;
}

.collection-status-wrapper {
  margin-bottom: 30px;
}

.collection-status-div {
  min-height: 50px;
}

.collection-status-active {
  background-color: rgb(23, 160, 23);
  /* border: 1px solid rgb(93, 93, 224); */
}
.collection-status-closed {
  background-color: rgb(30, 43, 119);
  /* border: 1px solid rgb(93, 93, 224); */
}
.collection-status-archived {
  background-color: rgb(72, 73, 72);
  /* border: 1px solid rgb(93, 93, 224); */
}
.collection-status-delete {
  background-color: rgb(201, 4, 4);
  /* border: 1px solid rgb(93, 93, 224); */
}

.collection-status-default {
  background-color: rgb(93, 93, 224);
  /* border: 1px solid rgb(93, 93, 224); */
}

.collection-current-status {
  border: 2px solid  #556271;
}


.collection-status-badge {
  margin-left: 10px;
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: small;
}
