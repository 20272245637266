.groups-page{
  width: 100%;
  margin: auto;
  text-align: center;
  color: white;
  width: min(100%,600px);
}

.groups-page button{
}

.groups-page h3, .groups-page h4{
  white-space: nowrap;
  text-align: left;
  color: white;
  margin: 10px 10px 5px 5px; 
}

.group-create {
  margin: 10px 0px 0px 0px;
  text-align: left;
}

.add-button{
  text-align: left;
  margin: 0px;
  padding: 12px;
  border-radius: 8px;
  margin-left: 5px;
}


.group-create input {
  margin: 0px;
  width: 65%;
}


.group-select {
  width: 300px;
  margin: 30px auto 30px auto;
  background: rgb(136, 136, 136);;
  padding: 20px 10px 20px 10px;
  border-radius: 5px;
}

.group-select select{
  width: 100%;
}


.group-button{
  display: inline-block;
  width: 40%;
}

.user-selection{
  display: flex;
  justify-content:center;
  text-align: center;
}

.user-selection select{
  width: 100%;
  display: inline-block;
  height: 200px;
  overflow: scroll;
  
}

.user-box{
  display: inline-block;
  /* width: 30%; */
  width: max(50px,50%);
}

.user-selection-buttons{
  padding-top: 80px;
  margin: 0px 10px 0px 10px;
  width: 100px;
}

.user-selection-button{
  color: black;
  margin: 5px auto 5px auto;
  width: 100%;
  cursor: pointer;
  padding: 15px 0px 15px 0px;
}

.group-name{
  text-transform: capitalize;
  font-weight: 400;
}

.user-filter {
  margin: 20px; 
  width: 250px;
  margin: auto;
}

.user-filter label{
 text-align: left;
}

.user-filter input{
  margin: 5px; 
  width: 80%;
}

.user-manager{

  padding: 20px 10px 20px 10px;
  margin: 20px 0px 20px 0px;
}


.custom-user {
  white-space: nowrap;
  text-align: left;
}

.custom-user input{
  width: calc(100% - 100px);
}

.custom-user button{
  color: black;
  padding: 12px;
}

.save-button{
  margin: 25px;
}