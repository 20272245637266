.tagged-images-title {
  color: white;
  text-align: center;
}

.tagged-images-container {
  /* padding: 1rem; */

  white-space: nowrap;
  background-color: #000;
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 2rem;
  justify-content: flex-start;
  padding: 1rem;
}

.tagged-img-wrapper {
  display: block;
  max-height: inherit;
}

.tagged-img {
  height: 20rem;
  max-height: 100%;
}
