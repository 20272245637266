.profile-card{
  background-color: #f2f2f2;
  width: 90%;
  padding: 10px;
  margin: auto;
  border-radius: 8px;
  min-width: 300px; 
  max-width: 800px; 
  margin-bottom: 30px;
  top:0px;
}

.profile-settings h3{
  margin: 10px;
}
.profile-settings > *{
  margin: 40px auto 0px auto;
}

.profile-settings h2 {
  color: #000;
  margin-bottom: 20px;
  font-weight: normal;
}

.profile-content{
  display: flex;
  background-color: transparent;
  position: relative;
}

.subtitle{
  text-transform:none;
  color: #6d6d6d;
  font-size: 18px;
  /* margin-left: 5%; */
}

.line{
  width: 35%;
  text-align: left;
}

.profile-field > label {
  white-space: nowrap;
  float: left;

}

.checkbox-input{
  height: 25px ;
  width: 25px !important;
}

#bio{
  flex: 100%;
  width: 100%;
}

.bio-input{
  resize: vertical;
  height: 10em;
}

.profile-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* padding: 2%; */
  gap: 2%;
}

.profile-field {
  /* display: flex;
  justify-content: space-around;
  align-items: flex-end; */
  margin: 5px 10px;
  flex: 40%;
  /* flex-grow: 1; */
}

.profile-key {
  text-transform: capitalize;
  text-align: left;
  word-wrap: none;
  color: #313131;
  margin-bottom: 3px;
  font-style: italic;
  font-weight: 300;
  width: 100%;
  float: left;
}

.profile-value {
  /* text-transform: capitalize; */
  text-align: left;
  font-size: inherit;
  padding-bottom: 1px;
  white-space: normal;
  /* margin-right: 30%; */
}

.profile-input{
  box-sizing: border-box;
  float:left;
  width: 100%;
  border: 0 !important;
  border-radius: 3px !important;
  font-size: inherit;
  padding: 10px 10px;
}

.textarea:focus, input:focus{
  outline: none;
}

.profile-locked-input{
  background-color: rgb(212, 212, 212);
  color: rgb(61, 61, 61);
}

.profile-edit-input{
  border-bottom: 1px solid rgb(112, 112, 112) !important;

  background-color: rgb(255, 255, 255);
}

.profile-header{
  /* text-transform: capitalize; */
  font-weight: 300;
  color: #ffffff;
}

.profile-card-header {
  position: relative;
}

.profile-card-header > h1{
  text-transform: capitalize;
}
.profile-card-header > h2{
  text-transform: capitalize;
}

.profile-card-header h1{
  font-weight: lighter;
  margin: 10px;
}

.profile-card-header h2{
  font-weight: normal;
}

.section-header {
  text-align: left;
}

.edit-btn{
  top: 20px;
  right: 50px;
}

.save-btn{
  margin: auto;
  margin-left: 30px;
}

.cancel-btn{
  margin: auto;
  top: 20px;
  right: 50px;
}

.update-success-text{
  width: 100%;
  height: 2em;
  margin: 0px;
}

.update-success-text-hidden{
  width: 100%;
  height: 2em;
  font-weight: 600;
  color: green;
  visibility: hidden;
  opacity: 0;
  margin: 0px;
  transition: visibility 0s 6s, opacity 6s ease-in;

}

.user-contributions-list{
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 2%;
  row-gap: 10px;
  justify-content: flex-start;
  margin: 10px auto 20px auto;
  max-height: min(600px, 100vw);
  overflow-y: scroll;

}

.profile-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.user-contribution{
  overflow: hidden;
  width: 32%;
  height: 20vw;
  max-height: 180px;
  min-height: 70px;
  align-self: center;
  object-fit: cover;
  cursor: pointer;
}
.user-contribution-archived{
  overflow: auto;
  width: 32%;
  height: 20vw;
  max-height: 180px;
  min-height: 70px;
  align-self: center;
  object-fit: cover;
  cursor: auto;
  padding: 5px;
  border: 0.5px solid grey;
}


.delete-modal{
  width: 600px;
  height: 400px;
  background-color: white;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
}

.delete-modal-content{
  position: relative;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.confirm-delete-input{
  width: 50%;
}

.edit-profile-help-text {
  /* margin-top: 20px;
  margin-bottom: 20px; */
  padding: 5px;
  max-width: 500px;
  margin: 20px auto;
}

.close-account-btn {
  margin-top: 500px;
}