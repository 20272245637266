
.contribution-page {
  background-color: #000;
  color: #ffffff;
}
.slider {
    margin: 50px auto 10px;
    width: 80vw;
    /* height: max(70vh, 800px); */
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
    border-radius: 4px;
    color: #ffffff;
  }
  
.slide {
  opacity: 0;
  /* height: 50vh;
  width: 80vw; */
  
  /* transition-duration: 0.5s ease; */
}

.slide.active {
  opacity: 1;
  transform: scale(1);
}

.focus-image-div {
  padding: 20px 10px;
  min-height: 20vh;
}
.similar-files {
  display: flex;
  overflow:auto;
  /* position: relative; */
}
.similar-files img {
  max-width: 30vw;
  max-height: 25vh;
  margin: 0px 5px;
}
.similar-files video {
  max-width: 25vw;
  max-height: 25vh;
  margin: 0px 5px;
}
.similar-files > .video-icon {
  position: absolute;
  top: 45%;
  left:20%;
}

.focus-image-div.portrait img {
  max-width: 30vw;
  max-height: 40vh;
  margin-top: 10px;
}


.focus-image-div.landscape img {
  max-width: 70vw;
  max-height: 70vh;
  margin-top: 10px;
}

.focus-image-div.portrait video {
  max-width: 30vw;
  max-height: 40vh;
  margin-top: 10px;
}
.focus-image-div.landscape video {
  max-width: 70vw;
  max-height: 70vh;
  margin-top: 10px;
}
.focus-image-div.landscape > .video-icon {
  position: absolute;
  top: 20%;
  left:45%;
}

  
.slider-arrow {
  color: #000000;
  font-size: 2rem;
  margin: auto;
  color: white;
}

.slider-arrow.left {
  margin-right: 30px;
}

.slider-arrow.right {
  margin-left: 30px;
}

.slideContent {
  display: flex;
}

.contribution-title{
  font-size: 32px;
  margin: 0px;
  line-height: 30px;
}

.contribution-sub-title{
  display: flex;
  align-items: start;
  flex-direction: row;
  margin: 10px 2px 50px;
  gap: 30px;
}

.contribution-photographer {
  display: flex;
  /* margin: 10px 0px; */
  align-items: center;
  gap: 10px;
}

.contribution-photographer p{
  font-weight: 800;
  text-transform: capitalize;
  margin: 0px;
}

.contribution-date{
  display: flex;
  align-items: center;
}

.slide-content-text {
  /* margin: 15px 20px 20px; */
  flex: 1;
}

.image-text {
  /* height: 70%; */
  margin: auto;
  width: 80%;
}

.slide-content-text-pairs {
  display: flex;
  margin-top: 10px;
  align-items: center;
}
  
.slide-content-text-pair-text {
  margin-left: 10px;
  text-align: left;
}

.location-link {
  margin-left: 10px;
  text-align: left;
  color: white;
}

.related-files {
  margin-top: 15px;
}

.related-files a {
  color: var(--secondary-color);
  font-size: medium;
  display: grid;
}
.thumbnail {
  max-width: 100px;
  object-fit: contain;
  margin: 5px;
}

.active-thumbnail {
  outline: #ffffff solid 2px;
  box-shadow: rgb(254 255 204) 1px 2px 16px 2px;
}

.contribution-top{
  display: flex;
}

.profile-container {
  display: flex;
  /* justify-content: center; */
  margin-left: 5%;
  align-items: center;
}


.user-edit-options {
  display: flex;
}

.contribution-tags {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 1px;
  align-items: baseline;
  align-content: center;
  margin-bottom: 100px;
}

.contribution-tag {
  color: #ffff;
  border-radius: 3px;
  font-size: 14px;
  padding: 10px 20px;
  display: flex;
  margin: 5px;
}

.thumbnails-wrapper {

}
  
.thumbnails {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 1000px;
  margin: auto;
}

.thumbnails > * img{
  height: 80px;
  margin: 0px;
  box-sizing: border-box;
  object-fit: cover;
  transition: box-shadow 0.07s ease-in-out;
}
  
  
.image-information {
  margin-top: 30px;
  margin-left: 2%;
}
.image-description {
  overflow-wrap: break-word;
  /* max-height: 100px; */
  overflow: auto;
}
.contribution-elements {
  padding: 0px 5%;
  /* margin-bottom: 30px; */
  text-align: left;
}

.mobile-arrows {
  margin-top: 20px;
}

.breadcrumbs {
  display: flex;
}

  /* Style the list */
ul.breadcrumb {
  padding: 0px 0px 5px 0px;
  list-style: none;
  margin-bottom: 0px;
  /* background-color: #dfdfdf; */
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 18px;
}


/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li:first-child +li:before  {
  padding: 8px;
  color: #ffffff;
  content: "/\00a0";
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
  color: #ffffff;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
  color: #01447e;
  text-decoration: underline;
}

.contribution-admin-section-contianer{ 
  color: #000;
  background-color: #f2f2f2;
  margin-top: 40px;
  padding: 10px 30px;
}

.contribution-admin-section-contianer h2 {
  color: #000;
}

@media (min-width: 1400px) {
  .focus-image-div img {
    margin-top: 0;
    max-width: 55vw;
    max-height: 70vh;
  }
  .focus-image-div video {
    margin-top: 0;
    max-width: 55vw;
    max-height: 70vh;
  }
}

@media (min-width: 800px) and (max-width: 999px){
  .focus-image-div img {
    margin-top: 10px;
    max-width: 60vw;
    max-height: 60vh;
  }
  .focus-image-div video {
    margin-top: 10px;
    max-width: 60vw;
    max-height: 60vh;
  }
  .focus-image-div > .video-icon {
    position: absolute;
    top: 45%;
    left: 35%;
  }
}

@media (min-width: 1000px) and (max-width: 1399px) {
  .focus-image-div img {
    margin-top: 0;
    max-width: 55vw;
    max-height: 450px;
  }
  .focus-image-div video {
    margin-top: 0;
    max-width: 55vw;
    max-height: 43vh;
  }
}

  
  @media (min-width: 1199px) {
    .focus-image-div {
      padding: 20px 10px;
      min-height: 20vh;
      flex: 1;
      text-align: right;
    }

    .slide {
      height: 40vh;
      width: 90vw;
    }
    .slide.active {
      display: flex;
      justify-content: center;
      gap: 30px;
    }
  
    .contribution-elements {
      padding: 0px 20%;
    } 
    .focus-image-div {
      /* flex-grow: 2; */
      padding: unset;
    }
  
    .image-information {
      margin-top: unset;
      margin-left: unset;
    }
    .image-description {
      margin: 10px 0px;
      text-align: left;
      /* max-height: 200px; */
      width: 400px;
      overflow: auto;
      height: 100%;
    }
    .slider {
      display: flex;
      width: 90vw;
      /* height: max(45vh, 500px); */
    }
    
    .slider-col-1 {
      /* border: 1px solid red; */
      flex: 0.3
    }
  
    .slider-col-2 {
      /* border: 1px solid purple; */
      height: 90%;
      flex: 3
    }
    .slider-col-3{
      /* border: 1px solid green; */
      /* flex: 3; */
      /* margin: auto; */
    }
    .slider-col-4{
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .slider-arrow { 
      position: unset;
      bottom: unset;
  
    }
    .slider-arrow.left {
      top: unset;
  
      margin-right: unset;
    }
    .slider-arrow.right {
  
      margin-left: unset;
    }
    .image-text {
      height: 100%;
      width: unset;
      margin: unset;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
  }



  @media screen and (min-width: 1000px) and (max-width: 1500px){
    .image-information {
      /* margin-top: 90px; */
    }
  }
  
  @media (min-width: 700px){
    .thumbnail {
      max-width: 150px;
    }
  
    .profile-container {
      margin-left: 4%
    }

    .breadcrumbs {
      margin-left: 4%
    }
  }

  @media screen and (min-width: 1501px) {
    .image-description {
      text-align: left;
      /* max-height: 300px; */
      width: 500px;
      height: 100%;
    }
  }


  .contribution-username{
    text-decoration: none;
    color: #ffffff;
    font-weight: inherit;
  }