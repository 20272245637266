.edit-organization-page > h1  {
  color: #ffffff;
}
.edit-organization-page > h2  {
  color: #ffffff;
}

.edit-organization-container {
  background: #f2f2f2;
  padding: 20px;
}

.edit-organization-translations-section h2 {
  color: #000;
}
.edit-organization-form-container form fieldset {
  border: none; 
}

.edit-organization-lang-selector-wrapper {
  padding: 20px;
  display: flex;
  align-items: center;
}

.edit-organization-lang-selector-custom-class {
  background-color: #ffffff;
  border: 1px solid grey;
  border-radius: 10px;
  width: 100px;
  cursor: pointer;
}

.edit-organization-lang-selector-custom-class:focus-visible {
  outline: none;
}


@media screen  and  (min-width: 1000px){
  .edit-organization-form-container {
    padding: 0px 20%;
  }
}



/* USER ADMINISTRATION */
.user-administration-page {
  padding: 30px;
}
.user-administration-page h1  {
  color: #ffffff;
}
.user-administration-page > h2  {
  color: #ffffff;
}

.organization-user-admin-container {
  background: #f2f2f2;
  padding: 20px;
  border-radius: 4px;
}

.role-pill {
  background-color: #000;
  color: #ffffff;
  font-size: x-small;
  font-weight: 500;
  border-radius: 30px;
  max-width: 90%;
  margin: auto;
  margin-bottom: 10px;
  padding: 3px 5px;
}
.status-pill {
  background-color: #000000;
  color: #ffffff;
  font-size: x-small;
  font-weight: 500;
  border-radius: 30px;
  max-width: 90%;
  margin: auto;
  margin-bottom: 10px;
  padding: 5px 6px;
}

.user-active {
  background-color: #1a2f72;
}

.user-suspended {
  background-color:rgb(170, 7, 7);
}
.organization-user-admin-role-status-container {
  margin: 50px 0px;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
}
.organization-user-admin-role-status-pair {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
  text-align: center;
  padding-bottom: 20px;
    border-bottom: 0.5px solid #dfdfdf;
}

@media screen  and  (min-width: 1000px){
  .role-pill {
    font-size: small;
  }

  .status-pill{font-size: small; }
}


.public-DraftEditor-content{
  min-height: 200px;
}

.createCollectionForm label{
  text-align: left;
}


/* ***** Organization about */
.about-organization-text {
  text-align: left;
  width: min(600px, 90%);
  margin: auto;
  font-size: large;
}

.organization-help-page-header {
  font-size: xx-large;
  margin: auto;
  padding-bottom:20px;
  text-transform: capitalize;
  font-weight: 400;
  margin-top: 30px;
}
/* Organization App translations stuff */

.edit-app-translations-container {
  background: #f2f2f2;
  padding:10px 5px;
  margin: auto;
}
.app-translation-card {
  background-color: #ffffff;
  padding:10px;
  border-radius: 10px;
  border-bottom: #e4dcdc;
  padding: 10px;
  margin-bottom: 20px;
}

.rotate-down {
  transform: rotate( -180deg );            
  transition: transform 300ms ease;
}

.rotate-up {
  transform: rotate( -360deg );            
  transition: transform 300ms ease;
}

.custom-translations-filed-pair {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.custom-translations-filed-pair > label {
  margin-right: 5px;
}

.nested-translations {
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  margin: 10px 0px;
}

@media screen  and  (min-width: 1000px){
  .edit-app-translations-container {
    width: 50%;
  }
}
