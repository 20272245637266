.app-header {
  background-color: black;
  max-height: 5vh;
  padding: 30px 5px;
  align-items: center;
  display: flex; 
  justify-content: space-between;
  color: #ffffff;
  align-items: center;
  font-weight: normal;
}


.header-left-content {
  justify-content: flex-start;
}
.header-right-content {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  align-items: center;
}

.header-profile-content {
  display: flex;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  font-weight: 900;
}
  
.header-item {
  margin: 10px;
}
.header-link-icon {
  text-decoration: none;
  text-transform: none;
  font-size: small;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
}

.header-link-text{
  text-decoration: none;
  text-transform: none;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  margin-right: 10px;
}

.header-username {
  text-decoration: none;
  color: white;
  text-transform: capitalize;
}

.header-signout {
  display: flex;
  justify-content: unset;
  text-decoration: none;
  color: white;
  text-transform: capitalize;
  align-items: center;
  font-size: 15px;
  padding-top: 2px;
  background: none;
  border: none;
  cursor: pointer;
  margin: auto 6px;
}


@media screen  and  (min-width: 600px){
  .header-username {
    margin-left: 10px;
  }
}

/* ******** Languange selector  */
.header-lang-selector-container {
  cursor: pointer;
}


.lang-select-trigger {
  border:none;
  border-radius: 100%;
}
.lang-select-trigger:focus-visible {
  outline: none;
}
.lang-select-content {
  min-width: 110px;
  background-color: #fff;
  border-radius: 6px;
  padding: 5px;
}

.lang-select-item {
  all: unset;
  font-size: 13px;
  line-height: 1px;
  color:#000;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 5px;
  position: relative;
  padding-left: 25px;
  user-select: none;

  /* '&[data-disabled]': {
    color: mauve.mauve8,
    pointerEvents: 'none',
  }, */
}
.lang-select-item:focus {
  background-color: rgba(238, 237, 237, 0.829);
  /* color: rgb(2, 2, 2); */
}
