.dialogContent {
  background-color: white;
  border-radius: 6px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 600px;
  max-height: 85vh;
  padding: 25px;
  overflow-y: auto;
}

.dialogTitle {
  text-align: center;
}

.confirm-type-dialog {
  text-align: center;
}

.dialogImages {
  display: flex;
  justify-content: center;
  /* overflow-x: scroll; */
}

.dialogContent form fieldset {
  border: none;
  
}


.dialogOverlay {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  inset: 0;
  animation: 10s ease-in;
}

.upload-process {
  color: #000;
}

.copy-prev-media-checkbox-container {
  padding: 0px 35px;
  margin-top: 20px;
}

.upload-process-media-arrows {
  display: flex;
  justify-content: space-between;

  position: sticky;
  top: 0px;
  background-color: #f2f2f2;
  z-index: 10;
}
