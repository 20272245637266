
.collections-page{
  background-color: black;
}

.org-banner{
  color: white;
  padding: 0px 10px 60px 10px;
}

.org-banner img{
  max-width: 90%;
}


.org-banner > H1{
  font-size: calc(1.5em + 3vw);
  margin: auto;
  padding-bottom:20px;
  font-weight: 400;
}

.org-banner > H2{
  font-size: 1em;
  font-weight: lighter;
  font-weight: 400;
  max-width: 700px;
  margin: auto;
}

.collection-page {
  background-color: #000;
}

.collection-header {
  padding-top:20px;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  height: 300px;
  color: #ffffff;
  font-size: 24px;
  position: relative;
}

.collection-header-content {
  display: grid;
  width: 100%;
}

.collection-header-wrapper {
  background: rgb(0,0,0);
  border-radius: 4px;
  background: radial-gradient(circle, rgba(0,0,0,0.5354516806722689) 0%, rgba(0,0,0,0.40379901960784315) 39%, rgba(0,0,0,0) 100%);
}

.collection-header-content-right {
  position: absolute;
  bottom: 30px;
  display: flex;
}


@media screen  and  (min-width: 600px){
  .collection-header{
    font-size:  34px;
  }
  .collection-header-content{
    grid-template-columns: 1fr 1fr;
    position: absolute;
    bottom: 20px;
  }
  .collection-header-content-right {
    right: 30px;
    bottom:10px;
    position: absolute;
  }
}
.collection-page-description {
  margin-bottom: 50px;
}
.collection-page-description a {
  width: 500px;
  /* display: block; */
  margin: auto;
}
.mediafile-icon-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mediafile-photographer-icon-wrapper {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #ffffff;
  display: block;
}

.mediafileRenderer-hover-wrapper {
  position: relative;
}


.mediaFileRenderer-hover-show {
  pointer-events: none;
  position: absolute;
  top: 25%;
  left: calc((100% - 10.5vw) / 2);
  /* bottom: 5%; */
  background: #000;
  color: #ffffff;
  border-radius: 4px;
  padding: 10px;
  width: 10vw;
  height: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: large;
  opacity: 100%;
  transition: opacity .2s ease-in-out;
}

.mediaFileRenderer-hover-show > p{
  margin: 0px;
  overflow: hidden;
}


.mediaFileRenderer-hover-hide {
  position: absolute;
  top: 25%;
  opacity: 0%;
  z-index: -1;
}



.collection-page-description h4{
  color: #ffffff;
  padding: 30px 10px;
  width: 80%;
  max-width: 800px;
  margin: auto;
  font-size: 1.3em;
  text-align: left;
  font-weight: 300;
}

.collection-page-description > h4 > p > a {
  color: #fff;
}
.contributions-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.contribution-image-section {
  padding: 10px 30px;
  display: flex;  
  flex-wrap: wrap;
}

.mini-gallery {
  width: 25%
}
.small-gallery {
  width: 45%
}

.big-gallery {
  width: 100%
}

.collection-contributions-list{
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* flex-direction: row; */
  flex-wrap: wrap;
  gap: 20px;
  /* justify-content:flex-start; */
  margin: auto;
}

@media screen and (min-width: 600px) {
  .collection-contributions-list{
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content:flex-start;
    margin: auto;
  }
  .collection-contribution{
    margin: unset !important;
    height: 20vw !important;
  }
  .collection-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr !important;
    column-gap: 1.5rem;
    row-gap: 1.5rem;
    padding: 0px 10px;
  }

  .collection-img img  {

    max-height: 20vw;
  }
  
}

.collection-contribution{
  overflow: hidden;
  max-width: 45vw;
  height: 25vw;
  align-self: center;
  object-fit: contain;
  margin: auto;
}
  
.react-photo-gallery--gallery  {
  width: 100%;
}
.contribution-image-container {
  border: 0.5px solid rgb(207, 204, 204);
  /* background-color: rgb(231, 231, 231); */
  margin: 5px;
  box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
  position: relative;
}

.contribution-image-container > .video-icon {
  position: absolute;
  top: 45%;
  left: 45%;
}

.g-video-icon {
  position: absolute;
  top: 45%;
  left: 45%;
}



@media (min-width: 2080px) {
 
  .collection-header {
    padding-top:0px;
    display: flex;
    justify-content: center;
    background-color: #fff;
  }

  .collection-header img {
    max-width:40vw;
    max-height:40vh;
  }
}




.collection-banner-text h1 {
  text-align: left;
}


.collection-banner-text{
  padding: 10px 20px;
}



.collection-description{
  width: 90%;
  max-width: 800px;
  font-weight: 500;
  text-align: left;
}

.collection-read-more {
  display: flex;
  justify-content: flex-end;
}

.collection-read-more span {
  font-size: large;
}
.collection-description > div > p {
  font-size: large;
}

.collection-description > div > p> a {
  color: #fff;
}

.collection-list{
  /* overflow: hidden; */
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.5rem;
  padding: 0px 10px;
}

.collection:hover{
  transform: scale(1.02);
}
/* OLD */
/* .collection {
  background-size: cover;
  background-position: center;
  transition: all .2s ease-in-out; 
  position: relative;
  background-color: black;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
} */
.collection {
  background-color: #ffffff;
  transition: transform 0.2s ease-in-out;
  /* padding: 10px 10px 10px 10px; */
}
.collection-img {
  /* padding: 10px 3px 2px 3px; */
  background-size: cover;
  background-position: center;
}
/* .collection-img img  {
  max-width: 100%;
} */


.click-for-more{
  text-decoration: underline;
  cursor: pointer;
}


.admin-panel {
  background-color: rgb(136, 136, 136);
  max-height: 5vh;
  align-items: center;
  display: flex; 
  justify-content: flex-start;
  font-size: small;
  color: #030303;
  font-weight: 500;
  padding: 6px 15px;
  overflow-x: auto;
}

.admin-panel-option-container {
  cursor: pointer;
  border-bottom: 1px solid black;
}

.admin-panel-option-container-multiple {
  cursor: pointer;
  display: flex;
}

.admin-panel-option-container-multiple p {
  margin-right: 5px;
  margin-bottom: 10px;
}

.admin-panel-option-container p {
  margin: 5px;
}

.admin-recent-posts-section {
  margin-top: 30px;
  width:90%;
  margin: auto;
  background: #f2f2f2f2;
  color: black;
  padding: 20px;
  margin-top: 40px;
}

.admin-recent-post-media {
  max-width: 10vw;
  height: 10vw;
  align-self: center;
  object-fit: contain;
}

.admin-recent-post-media-multiple {
  box-shadow:
    /* The top layer shadow */
    0 -1px 1px rgba(0,0,0,0.15),
    /* The second layer */
    0 -10px 0 -5px #eee,
    /* The second layer shadow */
    0 -10px 1px -4px rgba(0,0,0,0.15),
     /* The third layer */
    0 -20px 0 -10px #eee,
    /* The third layer shadow */
    0 -20px 1px -9px rgba(0,0,0,0.15);
}




.admin-recent-post-preview-media {
  /* position: relative; */
}

/* ---------- TABLE COMPONENTS CSSS */

.table-comp-cell {
  width: 150px;
  margin:auto;
  overflow-wrap: break-word
}

.table-comp-cell > .admin-cell-name {
  cursor: pointer;
}

.table-comp-cell .grow {
  width: auto;
  flex-grow: 1;
}

.table-comp-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 50px;
  margin: 15px 0px;
  border-bottom: 1px solid rgb(214, 214, 214);
}