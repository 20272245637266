
.text-pages {
  padding: 20px 40px;
  text-align: initial;
}
.text-pages h1, .text-pages h2, .text-pages h3, .text-pages p, .text-pages ul, .text-pages li {
  color: #ffffff;
  text-transform: none;
}

.text-pages a {
  color: #ffffff;
  text-decoration: underline;
}


.help-page > h1{
  text-align: center;  
}


/* About system page */

.about-page-hero-section {
  min-height: 500px;
  padding: 0;
  background: #f8f4f1;
  padding-bottom: 20px;
  /* blue: 94, 136, 158 */
  /* red: 204, 93, 18 */
  /* green: 136, 136, 50 */
  /* purple: 124, 105, 158 */
  /* background: rgb(94, 136, 158); */
}

.hero-row {
  display: block;
  margin: 0 auto;
  width: 95%;
  padding-top: 10px;
}

.hero-picure-area {
  /* border: 2px dotted gray; */

}
.hero-text {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  color: black !important;
  text-align: center;
}
.hero-text h1 {
  font-size: 70px;
  line-height: 1.16;
  letter-spacing: .56px;
  padding-bottom: 20px;
  font-weight: normal;
}

.hero-text p {
  width: 80%;
  font-size: 20px;
  margin: auto;
  text-align: initial;
}
.about-page-medium-row {
  margin: 0 auto;
}


.about-page-text-section {
  background: #ffffff;
  padding: 50px 50px;
  text-align: center;
}


.text-img-section {
  /* background: #e9e5e2; */
  padding: 0;
}

.about-page-text-section h2 {
  font-size: 40px;
  font-weight: normal;
}
.about-page-text-section h3 {
  font-size: 30px;
  font-weight: normal;
}

.text-class {
  width: 100%;
  margin: auto;
  text-align: initial;
}
.formatted-text-class {
  width: 100%;
  margin: auto;
  text-align: initial
}

.about-page-text-section p {
  font-size: 20px;
}
.about-feautures-title {
  margin-top: 80px;
  margin-bottom: 30px;
  text-align: center;
  
}
.divider {
  border: 1px solid #e9e5e2;
  margin-bottom: 50px;
  margin-top: 50px;
}
.about-page-features {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.about-page-features-group {
  margin: 20px;
}

.about-page-features-group-text h4 {
  font-size: 25px;
}
.about-page-features-group-text p {
  font-size: 18px;
}

.feature-icon {
  border:1px solid black;
  border-radius: 50% 50% 50% 50%; 
  padding: 10px 5px;
  margin: auto; 
  display: flex;
  justify-content: center;
  width: 50px;
}

.feature-icon-icon {
  margin: auto;
}

.videos-section {
  background: rgb(204, 93, 18 );
  min-height: 300px;
  padding: 20px 10px;
  display: flex;
  align-items: center;
}

.about-video-class {
  height: 30vh;
}
.system-editor-text {

}
.system-editor-text p {
  font-size: 20px;
  width: 100%;
  margin: auto;
  text-align: initial
}


@media screen  and  (min-width: 600px){
  .about-page-hero-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .formatted-text-class {
    width: 80%;
    margin: auto;
    text-align: initial
  }

  .about-page-features {
    flex-wrap: nowrap;
  }
  
  .text-img-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media screen  and  (min-width: 600px)and (max-width: 999px){
   .text-class {
    width: 85%;
    margin: auto;
    text-align: initial
  }
   .system-editor-text p {
    font-size: 20px;
    width: 85%;
    margin: auto;
    text-align: initial
  }
}
@media screen  and  (min-width: 1000px) and (max-width: 1500px){
  .text-class {
    width: 70%;
    margin: auto;
    text-align: initial
  }
  .system-editor-text p {
    font-size: 20px;
    width: 70%;
    margin: auto;
    text-align: initial
  }
  .hero-text > h1 {
    font-size: 60px;
  }
}

@media screen  and  (min-width: 1501px) {
  .text-class {
    width: 50%;
    margin: auto;
    text-align: initial
  }
  .system-editor-text p {
    font-size: 20px;
    width: 50%;
    margin: auto;
    text-align: initial
  }
  .about-page-hero-section {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
  }
}


/* Update PlatformPages */

.section-scope {
  display: grid;
  text-align: left;
  font-size: medium;
  margin-bottom: 40px;
}

.section-divider {
  border: 1px solid white;
  margin-bottom: 30px;
  margin-top: 15px;
}

.section-label {
  font-size: larger;
  margin-bottom: 15px;
}

