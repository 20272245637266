.login-container {
    padding: 20px;
    background-color: #e1e1e1;
    min-height: 90vh;
  }
  
  .login-content {
    margin: auto;
  }
  .login-error-div {
    min-height:40px;
  }
  .login-error {
    font-size: 0.9rem;
    color: red;
  }
  
  .register-div {
    margin-top: 80px;
  }
  
  .register-text {
    font-style: italic;
  }
  
  .register-success-icon {
    margin-top: 15px;
    margin-left: 15px;
  }
  
  .register-country-select {
    width: 100%;
    margin-bottom: 30px;
  }

  .password-reset-link{
    display: block;
    margin: 20px;
  }

#register-terms-conditions {
    color: #000;
}
  
  @media screen  and  (min-width: 600px){
    .login-content {
      width: 400px;
      margin: auto;
    }
  }

  .password-reset label{
    width: 100%;
    padding: 5px;
    text-align: left;
    font-style: italic;
    width: 300px;
    max-width: 90%;
    display: block;
  }

  .password-reset input{
    width: 100%;
  }

  .password-reset-request button{
    margin: 20px;
  }

  .password-reset-field{
    text-align: left;
    margin: 20px;
    max-width: 90%;
  }