.create-collection-page {
  background-color: #000;
}
.create-collection-page > h1 {
  color: #ffffff;
}
.create-collection-page  > h2 {
  color: #ffffff;
  margin-bottom: 30px;
}
.create-collection-container {
  padding: 30px 10%;
  border-radius: 3px;
  background-color: #f2f2f2 ;
  box-shadow: 0.3px 0.5px 0.7px rgb(0 0 0 / 10%), 0.8px 1.6px 2px -0.8px rgb(0 0 0 / 10%), 2.1px 4.1px 5.2px -1.7px rgb(0 0 0 / 10%), 5px 10px 12.6px -2.5px rgb(0 0 0 / 10%); 

}

.create-collection-container form {
  margin-top: 20px;
}

.create-collection-container form fieldset {
  border: none;
}

.image-selection-container {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.preview-collection-image {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  margin-bottom: 30px;
  height: 250px;
  padding: 10px 10px;
  width: 100%
}

.preview-collection-image img {
  width: inherit;
  max-height: 200px;
}

.contribution-settings-container {
  text-align: left;
}

.settings-wrapper {
  background: rgb(231, 230, 230);
  border-radius: 6px;
  padding: 30px 15px;
}

.questions-span {
  font-style: italic;
  margin-top: 10px;
}

.image-input {
  background: #dbdada;
  border: 1px solid #bfbcbc;
  padding: 5px 15px;
}


@media screen  and  (min-width: 600px){
  .image-selection-container  {
    width: 350px;
  }

  .preview-collection-image  {
    width: 350px;
    height: 250px;
  }

  .preview-collection-image img {
    width: 300px;
  }
}

.settings-q-wrapper {
  text-align: center;
  padding: 30px;
}

.setings-questions-wrapper {
  display: grid;
  text-align: unset;
}


.create-collection-translations-section h2 {
  align-self: flex-start;
  color: #000;
}
.create-collection-accordion-root { 
  border-radius: 6px;
  background-color: white;
}
.create-collection-accordion-header { 
  all: unset;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  text-transform: capitalize;
}

.create-collection-accordion-item { 
  overflow: hidden;
  margin-top: 1px;
  box-shadow: 0 2px 0 0 rgb(228, 223, 223);
}
.create-collection-accordion-item:first-child {
  border-top-left-radius: 4px;
  margin-top: 0;
  border-top-right-radius: 4px;
}
.create-collection-accordion-item:last-child{
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.create-collection-accordion-item:focus-within {
  position: relative;
  z-index: 1;
}
.create-collection-accordion-trigger { 
  all: unset;
  font-family: inherit;
  background-color: transparent;
  padding:0 20px;
  height: 45px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 15px;
  line-height: 1;
  color: blacK;
}

.create-collection-accordion-trigger[data-state="closed"] {
  background: white;
}
.create-collection-accordion-trigger[data-state="open"] {
  background: white;
}

@keyframes slideDown{
  from { 
    height: 0px; 
  }
  to { 
    height: var(--radix-accordion-content-height); 
  }
}
@keyframes slideUp{
  from { 
    height: var(--radix-accordion-content-height); 
  }
  to {
      height: 0px;
  }
}

.create-collection-accordion-content {
  overflow: hidden;
  font-size: 15px;
  color: black;
  padding: 15px 20px;
}

.create-collection-accordion-content[data-state="open"] {
  animation: slideDown 300ms linear;
}
.create-collection-accordion-content[data-state="closed"] {
  animation: slideUp 300ms linear;
}

.create-collection-accordion-trigger-icon {
  color: black;
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  display: inline-block;
}

[data-state="open"] .create-collection-accordion-trigger-icon {
  transform: rotate(180deg); 
}
